import { HTTP_INTERCEPTORS, provideHttpClient, withFetch } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent, MsalService } from '@azure/msal-angular';
import { InteractionType, IPublicClientApplication, PublicClientApplication, } from '@azure/msal-browser';
import { providers as backendServices } from '@models/api/index';
import { provideTanStackQuery, QueryClient, withDevtools } from '@tanstack/angular-query-experimental';
import { provideHighlightOptions } from 'ngx-highlightjs';
import { MarkdownModule, MARKED_OPTIONS, MarkedOptions, MarkedRenderer } from 'ngx-markdown';
import { ConfirmationService, MessageService } from 'primeng/api';
import urlJoin from 'url-join';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BaseGuard } from './auth/base.guard';
import { loginRequest, msalConfig, protectedResources } from './auth/config';
import { RoleGuard } from './auth/role.guard';
import { HomeComponent } from './components/home/home.component';
import { APIInterceptor } from './http.injector';
import { AppLayoutModule } from './layout/app.layout.module';
import { SafePipe } from './safe.pipe';
import { providers as appServices } from './services';
import { UserSettingsService } from './services/userSettings';
import { SharedModule } from './shared.module';

function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

/**
 * Configure the routes which when called via `httpClient.get()` MSAL will automatically inject the "Bearer ..."
 * authorization header.  Without entries in `protectedResourceMap` you will likely receive 401 errors. 
 */
function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  for(let resource of Object.values(protectedResources)){
    protectedResourceMap.set(resource.endpoint, resource.scopes);
  }

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: loginRequest.scopes,
    },
  };
}

export function markedOptionsFactory(): MarkedOptions {
  const renderer = new MarkedRenderer();

  // Customize how links are rendered
  renderer.link = (href: string, title: string, text: string) => {
    // Customize internal/external link behavior
    const isExternal = href.startsWith('http');
    const isRelative = !isExternal && !href.startsWith('/');

    if (isExternal) {
      // Open external links in a new tab
      return `<a href="${href}" target="_blank" rel="noopener noreferrer">${text}&nbsp;<i class="text-xs font-light pi pi-external-link"></i></a>`;
    }
    else if(isRelative){ 
      let p = new URLSearchParams(window.location.search);
      let params = Object.fromEntries(p.entries());
      // Append to existing path query parts stripping out '.'
      
      let existingPath = (params['path'] || '').split('/');
      // Drop the last part of the existing path if it's a file
      if(existingPath.length > 0){
        let lastItem = existingPath[existingPath.length-1];
        if(/.*?\.md/i.test(lastItem)){
          existingPath.pop();
        }
      }
      let parts = [...existingPath, ...href.split('/')].filter( (s,i) => i == 0 || (s != '.'));

      let path = urlJoin(location.origin, location.pathname, `?path=${urlJoin(parts)}`);
      return `<a href="${path}">${text}</a>`
    }
    else {
      // Internal links
      return `<a href="${href}">${text}</a>`;
    }
  };

  return {
    renderer,
    gfm: true, // GitHub flavored markdown
    breaks: false, // Enable line breaks as per the markdown spec
  };
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SafePipe,
    ],
  imports: [
    SharedModule, 
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MsalModule,
    ReactiveFormsModule,
    AppLayoutModule,
    MarkdownModule.forRoot({
      markedOptions:{
        provide: MARKED_OPTIONS,
        useFactory: markedOptionsFactory
      }
    })
],
  providers: [
    provideHttpClient(withFetch()),
    provideTanStackQuery(new QueryClient(), withDevtools()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: APIInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    provideHighlightOptions({
      coreLibraryLoader: () => import('highlight.js/lib/core'),
      lineNumbersLoader: () => import('ngx-highlightjs/line-numbers'),
      languages: {
        xml: () => import('highlight.js/lib/languages/xml')
      },
      themePath: 'assets/styles/highlightjs/androidstudio.min.css'
    }),
    MsalBroadcastService,
    MsalService,
    MsalGuard,
    BaseGuard,
    RoleGuard,
    UserSettingsService,
    ConfirmationService,
    MessageService,
    ...backendServices,
    ...appServices
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
}
)

export class AppModule { }
