import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { IderaComponent } from './components/idera/idera.component';
import { HomeComponent } from './components/home/home.component';
import { AppLayoutComponent } from './layout/app.layout.component';
import { BaseGuard } from './auth/base.guard';
import { RoleGuard } from './auth/role.guard';
import { DocsComponent } from './components/docs/docs.component';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [BaseGuard]
  }, {
    path: '',
    component: AppLayoutComponent,
    canActivate: [BaseGuard],
    children: [
      {
        path: 'voltaire',
        loadChildren: () => import('./components/voltaire/voltaire.module').then(m => m.VoltaireModule)
      }, {
        path: 'procurement',
        loadChildren: () => import('./components/procurement/procurement.module').then(m => m.ProcurementModule)
      },
      {
        canActivate:[RoleGuard],
        path: 'databricks',
        data: {
          expectedRoles: ['Databricks.Admin'], 
        },
        loadChildren: () => import('./components/databricks/databricks.module').then(m => m.DatabricksModule)
      },
    ]
  },
 {
    path: 'docs',
    component: DocsComponent
  }
  , {
    path: 'docs/:folder',
    component: DocsComponent,
    pathMatch: 'prefix'
  }
  , {
    path: 'idera',
    canActivate: [BaseGuard],
    component: IderaComponent
  },
  {
    // Needed for handling redirect after login
    path: 'auth',
    component: MsalRedirectComponent
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
