import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { PrimeIcons } from 'primeng/api';
import { ApiService } from '../services';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: any[] = [];

    constructor(public layoutService: LayoutService, public apiService: ApiService,) { }

    ngOnInit() {
        this.model = [
            {
                label: 'Home',
                items: [
                    { label: 'The Current', icon: 'pi pi-fw pi-home', routerLink: ['/'] }
                ]
            },
            {
                label: 'Voltaire',
                items: [
                    { label: 'Real Time', icon: PrimeIcons.CHART_LINE, routerLink: ['/voltaire/realtime'] },
                    { label: 'Day Ahead', icon: PrimeIcons.CHART_BAR, routerLink: ['/voltaire/dayahead'] },
                    { label: 'Transactions', icon: PrimeIcons.TABLE, routerLink: ['/voltaire/transactions'] },
                    { label: 'Strategies', icon: PrimeIcons.CALCULATOR, routerLink: ['/voltaire/strategies'] },
                    { label: 'Documentation', icon: PrimeIcons.BOOK, routerLink:['/docs/voltaire']}
                    // { label: 'Ercot', icon: PrimeIcons.EYE, routerLink: ['/voltaire/ercot'] },
                ]
            },
            {
                label: 'Procurement',
                items: [
                { label: 'Module Deliveries', icon: PrimeIcons.TABLE, routerLink: ['/procurement/moduledeliveries'] }
                ]
            },
            {
                label: 'Idera',
                items: [
                    { label: 'Idera', icon: PrimeIcons.SHARE_ALT, routerLink: ['/idera'] }
                ]
            }
        ];
        /** Only Display Databricks to Users who have access to the Databricks.Admin Role */
        if( this.apiService.activeAccount?.idTokenClaims?.roles &&
            this.apiService.activeAccount?.idTokenClaims?.roles.includes('Databricks.Admin')){
            this.model.push({
                label: 'DataBricks',
                items: [
                    { label: 'AccessManager', icon: PrimeIcons.TABLE, routerLink: ['/databricks'] }
                ]
            })
        }
    }
}
