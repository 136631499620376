<div class="layout-topbar">
    <a class="layout-topbar-logo" routerLink="">
        <img src="assets/images/ngr.png" alt="logo">

        <span> &nbsp;THE CURRENT</span>
        <br/>
    </a>

    <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="layoutService.onMenuToggle()">
        <i class="pi pi-bars"></i>
    </button>

    <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button" (click)="layoutService.showProfileSidebar()">
        <i class="pi pi-ellipsis-v"></i>
    </button>

    <div #topbarmenu class="flex layout-topbar-menu align-items-center" [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state().profileSidebarVisible}">
        
        <div *ngIf="apiService.loggedIn" class="text-lg">
            Hi, {{apiService.activeProfile?.givenName}}
        </div>

        <button *ngIf="!apiService.loggedIn" class="p-link layout-topbar-button" (click)="apiService.loginRedirect()">
            <i class="pi pi-user"></i>
            <span>Profile</span>
        </button>

        <button *ngIf="apiService.loggedIn" class="p-link layout-topbar-button" (click)="apiService.logoutRedirect()">
            <i class="pi pi-sign-out"></i>
            <span>Logout</span>
        </button>
        
        <button class="p-link layout-topbar-button" (click)="layoutService.toggleDarkMode()">
            <i *ngIf="layoutService.dark()" [class]="Icons.SUN"></i>
            <i *ngIf="!layoutService.dark()" [class]="Icons.MOON"></i>
            <span>Settings</span>
        </button>
    </div>
</div>