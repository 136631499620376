import { Component } from '@angular/core';

@Component({
  selector: 'app-idera',
  templateUrl: './idera.component.html',
  styleUrls: ['./idera.component.scss']
})
export class IderaComponent {

}
